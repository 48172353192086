import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button, Modal, Form, Row, Col, Dropdown,
} from 'react-bootstrap';

import { updateSla } from '../../store/actions/queues';
import { toggleTheme } from '../../store/actions/app';
import './Settings.scss';

function Settings() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { slaPercentage, slaWait } = useSelector((store) => (
    { slaPercentage: store.queues.slaPercentage, slaWait: store.queues.slaWait }
  ));
  const { appThemes, appTheme } = useSelector((store) => store.app);
  const [show, setShow] = useState(false);
  const [wait, setWait] = useState(slaWait);
  const [percentage, setPercentage] = useState(slaPercentage);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = () => {
    dispatch(updateSla({ slaPercentage: percentage, slaWait: wait }));
    handleClose();
  };

  const onChangeLanguage = (lng) => i18n.changeLanguage(lng);

  return (
    <>
      <Dropdown.Item variant="primary" onClick={handleShow}>{t('Settings')}</Dropdown.Item>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        animation={false}
        variant="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Settings')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="slaPercentage">
            <Form.Label column sm="4">{t('SLA Percentage')}</Form.Label>
            <Col sm="8">
              <Form.Control
                name="slaPercentage"
                type="number"
                placeholder={t('SLA Percentage')}
                value={percentage}
                min="0"
                max="100"
                onChange={(event) => setPercentage(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="slaWait">
            <Form.Label column sm="4">{t('SLA Wait')}</Form.Label>
            <Col sm="8">
              <Form.Control
                name="slaWait"
                type="number"
                placeholder={t('SLA Wait')}
                value={wait}
                min="0"
                max="100"
                onChange={(event) => setWait(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="slaWait">
            <Form.Label column sm="4">{t('Language')}</Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                onChange={(event) => onChangeLanguage(event.target.value)}
                defaultValue={i18n.language}
              >
                <option value="en">English</option>
                <option value="it">Italian</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="slaWait">
            <Form.Label column sm="4">{t('Theme')}</Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                onChange={(event) => dispatch(toggleTheme(event.target.value))}
                defaultValue={appTheme}
              >
                {appThemes.map((theme) => (
                  <option value={theme}>{theme}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>{t('Close')}</Button>
          <Button variant="primary" onClick={handleSave}>{t('Save')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Settings;
