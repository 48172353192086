import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListGroup } from 'react-bootstrap';

import { queuesSelect } from '../../store/actions/queues';
import QueueWidget from '../QueueWidget';

import './Queues.scss';

function Queues() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const select = (q) => dispatch(queuesSelect(q));
  const { queues, selected, toggled } = useSelector((state) => ({
    queues: state.wtapi.queues,
    selected: state.queues.selected,
    toggled: state.app.leftSidebar,
  }));

  return (
    <div className={`Queues ${toggled}`}>
      <div id="sidebar-wrapper">
        <h1 className="sidebar-brand">{t('Queues')}</h1>
        <ListGroup variant="flush" className="sidebar-nav">
          {queues.map((queue) => (
            <ListGroup.Item
              action
              key={queue.getId()}
              disabled={selected.includes(queue.getId())}
              onClick={() => select(queue.getId())}
            >
              {queue.getName()}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div id="page-content-wrapper">
        <div className="container-fluids">
          <div className="row queues" id="queues">
            {selected.map((q) => (
              (queues.find((queue) => queue.getId() === q))
                ? <QueueWidget key={q} queue={queues.find((queue) => queue.getId() === q)} />
                : <div key={q} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Queues;
