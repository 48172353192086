import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav } from 'react-bootstrap';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { toggleLeftSidebar } from '../../store/actions/app';

import Queues from '../Queues';
import UserButton from '../UserButton';
import Todo from '../Todo';

import './App.scss';

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const appTheme = useSelector((store) => store.app.appTheme);

  const toggle = () => dispatch(toggleLeftSidebar());

  return (
    <div className="App">
      <Router>
        <Navbar
          collapseOnSelect
          expand="sm"
          fixed="top"
        >
          <Navbar.Brand>
            <Nav.Link
              href="#"
              onClick={toggle}
              className="navbar-toggler-icon"
            />
          </Navbar.Brand>
          <Navbar.Brand>{t('Wallboards')}</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/queues"><Nav.Link>{t('Queues')}</Nav.Link></LinkContainer>
              <LinkContainer to="/groups"><Nav.Link>{t('Groups')}</Nav.Link></LinkContainer>
              <LinkContainer to="/user"><Nav.Link>{t('User')}</Nav.Link></LinkContainer>
            </Nav>
            <Nav className="ml-auto">
              <UserButton />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route path="/settings" component={Todo} />
          <Route path="/groups" component={Todo} />
          <Route path="/user" component={Todo} />
          <Route path="/queues" component={Queues} />
          <Route path="/" component={Queues} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
