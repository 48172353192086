import {
  APP_TOGGLE_LEFT_SIDEBAR,
  APP_TOGGLE_THEME,
} from '../../constants/action-types';

export const toggleLeftSidebar = (qId) => ({ type: APP_TOGGLE_LEFT_SIDEBAR, payload: qId });
export const toggleTheme = (theme) => ({ type: APP_TOGGLE_THEME, payload: theme });

let initialState = {
  leftSidebar: '',
  appTheme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'DarkTheme' : 'LightTheme',
  appThemes: ['DarkTheme', 'LightTheme', 'PinkTheme'],
};

try {
  const serializedState = localStorage.getItem('appState');
  initialState = {
    ...initialState,
    ...JSON.parse(serializedState),
    ...{ appThemes: initialState.appThemes },
  };
} catch (error) {
  // eslint-disable-next-line no-console
  console.log(error);
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        leftSidebar: state.leftSidebar === '' ? 'toggled' : '',
      };
    case APP_TOGGLE_THEME:
      return {
        ...state,
        appTheme: action.payload,
      };
    default:
      return state;
  }
};
