import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '@fortawesome/fontawesome-free/css/all.css';

import store from './store';

import './i18nextConf';
import './index.scss';
import './themes.scss';
import ConnectWtapi from './components/ConnectWtapi';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

import { WTAPI_CONNECTED } from './constants/wtapiStatus';

window.store = store;

function WtapiConnector() {
  const { t } = useTranslation();
  const wtapiStatus = useSelector((state) => state.wtapi.status);
  const appTheme = useSelector((state) => state.app.appTheme);
  const appThemes = useSelector((state) => state.app.appThemes);

  useEffect(() => { document.title = t('Starsystem Wallboards'); }, []);
  useEffect(() => {
    document.body.classList.remove(...appThemes);
    document.body.classList.add(appTheme);
  }, [appTheme]);

  return wtapiStatus !== WTAPI_CONNECTED
    ? <ConnectWtapi />
    : <App />;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <WtapiConnector />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
