export const APP_TOGGLE_LEFT_SIDEBAR = 'APP_TOGGLE_LEFT_SIDEBAR';
export const APP_TOGGLE_THEME = 'APP_TOGGLE_THEME';

export const WTAPI_ADD_QUEUE = 'WTAPI_ADD_QUEUE';
export const WTAPI_REMOVE_QUEUE = 'WTAPI_REMOVE_QUEUE';

export const WTAPI_QUEUE_UPDATED = 'WTAPI_QUEUE_UPDATED';

export const WTAPI_PRESENCE_CHANGED = 'WTAPI_PRESENCE_CHANGED';
export const WTAPI_PERSONAL_PRESENCE_CHANGED = 'WTAPI_PERSONAL_PRESENCE_CHANGED';
export const WTAPI_DEVICES_CHANGED = 'WTAPI_DEVICES_CHANGED';
export const WTAPI_DEVICES_CHANGE_SELECTED = 'WTAPI_DEVICES_CHANGE_SELECTED';

export const WTAPI_RESTORE_CREDENTIALS = 'WTAPI_RESTORE_CREDENTIALS';
export const WTAPI_ON_RESTORE_CREDENTIALS = 'WTAPI_ON_RESTORE_CREDENTIALS';
export const WTAPI_RESTORE_CREDENTIALS_FAILED = 'WTAPI_RESTORE_CREDENTIALS_FAILED';

export const WTAPI_CONNECT = 'WTAPI_CONNECT';
export const WTAPI_CONNECTING = 'WTAPI_CONNECTING';
export const WTAPI_CONNECTED = 'WTAPI_CONNECTED';
export const WTAPI_DISCONNECT = 'WTAPI_DISCONNECT';
export const WTAPI_DISCONNECTED = 'WTAPI_DISCONNECTED';

export const QUEUE_SELECT = 'QUEUE_SELECT';
export const QUEUE_DESELECT = 'QUEUE_DESELECT';
export const QUEUE_UPDATE_SLA = 'QUEUE_UPDATE_SLA';
