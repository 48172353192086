import { combineReducers } from 'redux';
import { wtapiReducer } from './actions/wtapi';
import { queueReducer } from './actions/queues';
import { appReducer } from './actions/app';

export default combineReducers({
  app: appReducer,
  wtapi: wtapiReducer,
  queues: queueReducer,
});
