import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ToggleButton, Dropdown, ButtonGroup, Form, Spinner,
} from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUserTie } from '@fortawesome/free-solid-svg-icons';

import Settings from '../Settings';
import { wtapiDisconnect, wtapiChangeSelectedDevice } from '../../store/actions/wtapi';

import './UserButton.scss';

const { wtapi, WTAPI } = window;

function UserButton() {
  const statuses = ['online', 'away', 'dnd'];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    statusIcon, statusMessage, devices, selectedDevice,
  } = useSelector((state) => ({
    statusIcon: state.wtapi.personalPresece
      ? state.wtapi.personalPresece.getStatusIcon()
      : 'n/a',
    statusMessage: state.wtapi.personalPresece
      // eslint-disable-next-line no-underscore-dangle
      ? state.wtapi.personalPresece._status
      : '',
    devices: state.wtapi.devices,
    selectedDevice: state.wtapi.selectedDevice,
  }));
  // const appTheme = useSelector((store) => store.app.appTheme);
  const [messageText, setMessageText] = useState(statusMessage);

  useEffect(() => {
    setMessageText(statusMessage || '');
  }, [statusMessage]);

  const updatePresence = (status, message) => {
    const builder = new WTAPI.Presence.Builder();

    // Set status
    switch (status) {
      case 'away':
        builder.setAway();
        break;
      case 'dnd':
        builder.setDND();
        break;
      default:
        builder.setOnline();
    }
    // Set status message
    if (message.length > 0) {
      builder.setStatusMessage(message);
    }

    // // Set status until
    // if (until.length > 0) {
    //     builder.setStatusUntil(this._parseDate(until));
    // }
    wtapi.presence.changePersonalPresence(builder.build(), () => {});
  };

  const changePresence = (e) => {
    const status = e.currentTarget.value;
    updatePresence(status, statusMessage);
  };

  const debounceMessage = useCallback(_.debounce(updatePresence, 1000), []);

  const setMessage = (e) => {
    const message = e.target.value;
    setMessageText(message);
    debounceMessage(statusIcon, message);
  };

  const changeDevice = (e) => {
    const deviceId = e.currentTarget.value;
    const device = devices.find((d) => d.getId() === deviceId);
    if (device) {
      dispatch(wtapiChangeSelectedDevice(device));
    } else {
      dispatch(wtapiChangeSelectedDevice(devices[0]));
    }
  };

  return (
    <Dropdown as={ButtonGroup} className="UserButton" variant="dark">
      <Dropdown.Toggle variant={{ online: 'success', away: 'warning', dnd: 'danger' }[statusIcon]}>
        <img className="user-botton-icon" src={`${wtapi.pbxUrl}/collaboration/lua/avatar.lua?${wtapi.getExtension()}`} alt="text" />
        {/* <FontAwesomeIcon className="user-botton-icon" icon={faUserTie} size="lg" /> */}
        {statusIcon === 'n/a' ? (
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        ) : statusIcon}
        <small>{selectedDevice ? selectedDevice.getName() : ''}</small>
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        <Dropdown.Header>{t('Devices')}</Dropdown.Header>
        <Form className="mx-3 my-2 w-auto">
          {devices.map((device) => (
            <div key={device.getId()} className="mb-3">
              <Form.Check
                checked={selectedDevice && selectedDevice.getId() === device.getId()}
                name="device"
                type="radio"
                value={device.getId()}
                key={device.getId()}
                id={`device-menu-${device.getId()}`}
                label={device.getName()}
                onChange={changeDevice}
              />
            </div>
          ))}
        </Form>
        <Dropdown.Divider />
        <Dropdown.Header>{t('Change Status')}</Dropdown.Header>
        <ButtonGroup className="d-flex mx-3 my-2 w-auto" toggle>
          {statuses.map((status) => (
            <ToggleButton
              type="radio"
              variant="theme"
              value={status}
              key={status}
              checked={status === statusIcon}
              onChange={changePresence}
            >
              {status}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <Form.Group controlId="userStatusMessage" className="mx-3 my-2 w-auto">
          <Form.Label>
            {
              messageText !== statusMessage ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : ''
            }
            {t('Status Message')}
          </Form.Label>
          <Form.Control as="textarea" rows={1} value={messageText} onChange={setMessage} />
        </Form.Group>
        <Dropdown.Divider />
        <Settings />
        <Dropdown.Item
          onClick={() => dispatch(wtapiDisconnect())}
        >
          {t('Logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserButton;
