import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import addWtapiListeners from './actions/wtapi';

import rootReducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const localStorageMiddleware = ({ getState }) => (next) => (action) => {
  const result = next(action);
  localStorage.setItem('queuesState', JSON.stringify(getState().queues));
  localStorage.setItem('appState', JSON.stringify(getState().app));
  return result;
};

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunk,
      localStorageMiddleware,
    ),
  ),
);

addWtapiListeners(store.dispatch, store.getState);

export default store;
