import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
  Container, Row, Col, Card, Button, Spinner, Alert,
} from 'react-bootstrap';

import { wtapiConnect } from '../store/actions/wtapi';
import { WTAPI_CONNECTING } from '../constants/action-types';

function ConnectWtapi() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.wtapi.status === WTAPI_CONNECTING);
  const { t } = useTranslation();
  const onConnectClick = () => dispatch(wtapiConnect());

  return (
    <Container className="ConnectWtapi">
      <Row>
        <Col />
        <Col className="text-center">
          <h2 style={{ marginTop: 50 }}>{t('Wallboards')}</h2>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col />
        <Col>
          <Card style={{ marginTop: 50, marginBottom: 50 }} className="text-center">
            <Card.Body>
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={!isLoading ? onConnectClick : null}
              >
                {
                  isLoading
                    ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        {t('Connecting…')}
                      </>
                    )
                    : t('Connect')
                }
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col />
        <Col>
          <Alert variant="success">
            <Alert.Heading>
              {t('Auth section')}
            </Alert.Heading>
            <Trans i18nKey="Auth section message">
              Connect and digit your Wildix Credentials! It will
              enable you to get into the interface.
              <br />
              If any issues rise please contact us at
              <a href="mailto:issues@starsystem.biz">
                issues@starsystem.biz
              </a>
              .
            </Trans>
            <hr />
            <p className="mb-0" />
          </Alert>
        </Col>
        <Col />
      </Row>
    </Container>
  );
}

export default ConnectWtapi;
