import {
  WTAPI_ADD_QUEUE,
  WTAPI_REMOVE_QUEUE,
  WTAPI_CONNECTED,
  WTAPI_DISCONNECT,
  WTAPI_CONNECTING,
  WTAPI_DISCONNECTED,
  WTAPI_ON_RESTORE_CREDENTIALS,
  WTAPI_RESTORE_CREDENTIALS_FAILED,
  WTAPI_QUEUE_UPDATED,
  WTAPI_PRESENCE_CHANGED,
  WTAPI_PERSONAL_PRESENCE_CHANGED,
  WTAPI_DEVICES_CHANGED,
  WTAPI_DEVICES_CHANGE_SELECTED,
} from '../../constants/action-types';

export const { wtapi } = window;

export const wtapiDisconnect = () => (dispatch) => {
  wtapi.deleteSavedCredentials();
  wtapi.disconnect();
  dispatch({ type: WTAPI_DISCONNECT });
};

export const wtapiConnect = () => (dispatch) => {
  wtapi.connect();
  dispatch({ type: WTAPI_CONNECTING });
};

export const wtapiChangeSelectedDevice = (device) => (dispatch) => {
  dispatch({ type: WTAPI_DEVICES_CHANGE_SELECTED, payload: device });
};

export default function addWtapiListeners(dispatch) {
  wtapi.on('connected', (data) => {
    wtapi.roster.getRoster(() => {
      wtapi.queues.subscribe();
      wtapi.presence.subscribe([wtapi.getExtension()], () => {});
    });
    dispatch({ type: WTAPI_CONNECTED, payload: data });
  });

  wtapi.on('disconnected', (data) => dispatch({ type: WTAPI_DISCONNECTED, payload: data }));

  // Queues demo
  wtapi.queues.on('queue_added', (data) => dispatch({ type: WTAPI_ADD_QUEUE, payload: data }));
  wtapi.queues.on('queue_removed', (data) => dispatch({ type: WTAPI_REMOVE_QUEUE, payload: data }));
  wtapi.queues.on('member_added', (data) => dispatch({ type: WTAPI_QUEUE_UPDATED, payload: data }));
  wtapi.queues.on('member_updated', (data) => dispatch({ type: WTAPI_QUEUE_UPDATED, payload: data }));
  wtapi.queues.on('member_removed', (data) => dispatch({ type: WTAPI_QUEUE_UPDATED, payload: data }));
  wtapi.queues.on('call_added', (data) => dispatch({ type: WTAPI_QUEUE_UPDATED, payload: data }));
  wtapi.queues.on('call_removed', (data) => dispatch({ type: WTAPI_QUEUE_UPDATED, payload: data }));
  wtapi.queues.on('user_join', (data) => dispatch({ type: WTAPI_QUEUE_UPDATED, payload: data }));
  wtapi.queues.on('user_left', (data) => dispatch({ type: WTAPI_QUEUE_UPDATED, payload: data }));
  wtapi.presence.on('presence_changed', (user, presence) => dispatch({ type: WTAPI_PRESENCE_CHANGED, payload: { user, presence } }));
  wtapi.presence.on('personal_presence_changed', (presence) => dispatch({ type: WTAPI_PERSONAL_PRESENCE_CHANGED, payload: presence }));
  wtapi.telephony.on('devices_changed', (devices) => dispatch({ type: WTAPI_DEVICES_CHANGED, payload: devices }));

  wtapi.on('restoreCredentials', (data) => {
    if (data === null) {
      dispatch({ type: WTAPI_DISCONNECTED, payload: data });
    } else {
      dispatch({ type: WTAPI_CONNECTED, payload: data });
    }
  });

  wtapi.restoreSavedCredentials();
}

const initialState = {
  status: WTAPI_CONNECTING,
  queues: [],
  personalPresece: false,
  devices: [],
  selectedDevice: false,
};

export const wtapiReducer = (state = initialState, action) => {
  switch (action.type) {
    case WTAPI_ADD_QUEUE:
      return {
        ...state,
        queues: [...state.queues, action.payload]
          // eslint-disable-next-line no-nested-ternary
          .sort((a, b) => ((a.getName().toLowerCase() > b.getName().toLowerCase()) ? 1
            : ((b.getName().toLowerCase() > a.getName().toLowerCase()) ? -1 : 0))),
      };
    case WTAPI_REMOVE_QUEUE:
      return {
        ...state,
        queues: state.queues.filter((s) => s.getId() !== action.payload.getId()),
      };
    case WTAPI_PRESENCE_CHANGED:
      return {
        ...state,
        queues: state.queues.map((queue) => {
          const q = queue;
          const members = queue.getMembers();
          const isMember = members.find((member) => (
            member.getExtension() === action.payload.user.getExtension()
          ));
          if (isMember) {
            q.t = Math.random();
          }
          return q;
        }),
      };
    case WTAPI_PERSONAL_PRESENCE_CHANGED:
      return {
        ...state,
        personalPresece: action.payload,
      };
    case WTAPI_DEVICES_CHANGED:
      return {
        ...state,
        devices: action.payload,
        // eslint-disable-next-line no-nested-ternary
        selectedDevice: !state.selectedDevice
          ? (action.payload.length > 0 ? action.payload[0] : false)
          : state.selectedDevice,
      };
    case WTAPI_DEVICES_CHANGE_SELECTED:
      return {
        ...state,
        selectedDevice: action.payload,
      };
    case WTAPI_QUEUE_UPDATED:
      return {
        ...state,
        queues: state.queues.map((queue) => (
          queue.getId() === action.payload.getId() ? action.payload : queue
        )),
      };
    case WTAPI_ON_RESTORE_CREDENTIALS:
      return { ...state, status: action.payload ? WTAPI_CONNECTED : WTAPI_DISCONNECTED };
    case WTAPI_CONNECTED:
      return { ...state, status: WTAPI_CONNECTED };
    case WTAPI_CONNECTING:
      return { ...state, status: WTAPI_CONNECTING };
    case WTAPI_RESTORE_CREDENTIALS_FAILED:
      return { ...state, queues: [], status: WTAPI_DISCONNECTED };
    case WTAPI_DISCONNECTED:
      return { ...state, queues: [], status: WTAPI_DISCONNECTED };
    default:
  }
  return state;
};
