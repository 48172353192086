import React from 'react';

import './Todo.scss';

function Todo() {
  return (
    <div className="Todo">ToDo</div>
  );
}

export default Todo;
