import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { queuesDeselect } from '../../store/actions/queues';

import './QueueWidget.scss';

const { $ } = window;

const QueueWidget = (props) => {
  const { queue } = props;
  const queueSelector = useSelector((state) => (
    state.wtapi.queues.find((q) => q.getId() === queue.getId()).t
  ));
  const { slaPercentage, slaWait } = useSelector((store) => (
    { slaPercentage: store.queues.slaPercentage, slaWait: store.queues.slaWait }
  ));
  const elmt = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const $elmt = $(elmt.current);
    $elmt.queue({ queue, sla_wait: slaWait, sla_percentage: slaPercentage });
    $elmt.queue('resetSLA', { sla_wait: slaWait, sla_percentage: slaPercentage });
    $elmt.queue('drawTable');
    const interval = setInterval(() => $elmt.queue('time'), 1000);
    $elmt.find('.close-queue').click(() => {
      dispatch(queuesDeselect(queue.getId()));
      $elmt.remove();
    });
    return () => {
      // $elmt.remove();
      clearInterval(interval);
    };
  }, [queue, queueSelector, slaWait, slaPercentage]);

  return <div className="QueueWidget col-12 col-sm-6 col-xl-4" ref={elmt} />;
};

export default QueueWidget;
