import {
  QUEUE_SELECT,
  QUEUE_DESELECT,
  WTAPI_DISCONNECTED,
  QUEUE_UPDATE_SLA,
} from '../../constants/action-types';

export const queuesSelect = (qId) => ({ type: QUEUE_SELECT, payload: qId });
export const queuesDeselect = (qId) => ({ type: QUEUE_DESELECT, payload: qId });
export const updateSla = (sla) => ({ type: QUEUE_UPDATE_SLA, payload: sla });

let initialState = {
  selected: [],
  slaPercentage: 60,
  slaWait: 20,
};

try {
  const serializedState = localStorage.getItem('queuesState');
  initialState = { ...initialState, ...JSON.parse(serializedState) };
} catch (error) {
  // eslint-disable-next-line no-console
  console.log(error);
}

export const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUEUE_SELECT:
      return {
        ...state,
        selected: [...state.selected, action.payload],
      };
    case QUEUE_DESELECT:
      return {
        ...state,
        selected: state.selected.filter((s) => s !== action.payload),
      };
    case WTAPI_DISCONNECTED:
      return { ...state };
    case QUEUE_UPDATE_SLA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
